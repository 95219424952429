import { initializeApp } from "firebase/app";
import {
  GoogleAuthProvider,
  getAuth,
  FacebookAuthProvider,
  signInWithPopup,
  OAuthProvider
} from "firebase/auth";

import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";

import { getFunctions, httpsCallable } from "firebase/functions";
import { getStripePayments } from "@stripe/firestore-stripe-payments";
import { getAnalytics } from "firebase/analytics";
import { getStorage, connectStorageEmulator } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);
const analytics = getAnalytics(app);

const googleProvider = new GoogleAuthProvider();
const facebookProvider = new FacebookAuthProvider();


if (process.env.NODE_ENV === "development") {
  // getFunctions(app).customDomain = "http://localhost:5004/world-radio-league-prod/us-central1"
  // connectFirestoreEmulator(db, 'localhost', 8183);
  // connectStorageEmulator(storage, 'localhost', 7173);
}

const getDiscourseSSO = httpsCallable(getFunctions(app), 'discourseFirebaseAuth')
const createStripeCheckoutSession = httpsCallable(getFunctions(app), 'stripeCreateCheckoutSession')
const createStripePortalSession = httpsCallable(getFunctions(app), 'ext-firestore-stripe-payments-createPortalLink')
const getUsersForMap = httpsCallable(getFunctions(app), 'getUsersForMap')
const getUsersForGlobe = httpsCallable(getFunctions(app), 'getUsersForGlobe')
const getUserLeaderBoardDataByWeek = httpsCallable(getFunctions(app), 'getUserLeaderBoardByWeek')
const getUserLeaderBoardByModeWeek = httpsCallable(getFunctions(app), 'getUserLeaderBoardByModeWeek')
const getUserDistanceLeaderBoard = httpsCallable(getFunctions(app), 'getUserDistanceLeaderBoard')
const getDXLeaderBoard = httpsCallable(getFunctions(app), 'getDXLeaderBoard')
const getStateLeaderBoard = httpsCallable(getFunctions(app), 'getStateLeaderBoard')
const exportContactsADI = httpsCallable(getFunctions(app), 'exportContactsADI')
const adminDeleteUser = httpsCallable(getFunctions(app), 'adminDeleteUser')
const adminDisableUser = httpsCallable(getFunctions(app), 'adminDisableUser')
const adminEnableUser = httpsCallable(getFunctions(app), 'adminEnableUser')
const adminUpdateSubscription = httpsCallable(getFunctions(app), 'adminUpdateSubscription')
const getDailyLeaders = httpsCallable(getFunctions(app), 'getDailyLeaders')
const deleteLogbookRecords = httpsCallable(getFunctions(app), 'deleteLogbookRecords')
const updateAutoRenew = httpsCallable(getFunctions(app), 'updateAutoRenew')


const getAllSpots = httpsCallable(getFunctions(app), 'getAllSpots')
const getCountryAndStateFromGrid = httpsCallable(getFunctions(app), 'getCountryAndStateFromGrid')

const getCountryFromCallSign = httpsCallable(getFunctions(app), 'getCountryFromCallSign')
const calculateContactDistance = httpsCallable(getFunctions(app), 'calculateContactDistance')
const getLocationFromAddress = httpsCallable(getFunctions(app), 'getLocationFromAddress')
const getZonesFromCoordinate = httpsCallable(getFunctions(app), 'getZonesFromCoordinate')
const calculateLogBookAnalytics = httpsCallable(getFunctions(app), 'calculateLogBookAnalytics')
const deleteAccount = httpsCallable(getFunctions(app), 'deleteAccount')
const calculateProfileAnalytics = httpsCallable(getFunctions(app), 'calculateProfileAnalytics')
const exportUserContacts = httpsCallable(getFunctions(app), 'exportUserContacts', {
  timeout: 540000
})
const calculateHardwareAnalytics = httpsCallable(getFunctions(app), 'calculateHardwareAnalytics')
const sendAutoUpdateParksDataTrigger = httpsCallable(getFunctions(app), 'sendAutoUpdateParksDataTrigger')

const payments = getStripePayments(app, {
  productsCollection: "Products",
  customersCollection: "Customers",
});

export {
  auth,
  googleProvider,
  facebookProvider,
  db,
  storage,
  getDiscourseSSO,
  createStripeCheckoutSession,
  payments,
  createStripePortalSession,
  analytics,
  getUsersForMap,
  getUsersForGlobe,
  getUserLeaderBoardDataByWeek,
  getUserLeaderBoardByModeWeek,
  getUserDistanceLeaderBoard,
  getDXLeaderBoard,
  getStateLeaderBoard,
  getCountryFromCallSign,
  exportContactsADI,
  adminDeleteUser,
  adminDisableUser,
  adminEnableUser,
  adminUpdateSubscription,
  getDailyLeaders,
  deleteLogbookRecords,
  getAllSpots,
  getCountryAndStateFromGrid,
  calculateContactDistance,
  getLocationFromAddress,
  getZonesFromCoordinate,
  updateAutoRenew,
  calculateLogBookAnalytics,
  deleteAccount,
  calculateProfileAnalytics,
  signInWithPopup,
  OAuthProvider,
  exportUserContacts,
  calculateHardwareAnalytics,
  sendAutoUpdateParksDataTrigger
};
