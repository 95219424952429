/* eslint-disable no-useless-concat */
import {
  collection,
  doc,
  limit,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { allErrorHandler } from "helpers/ErrorHandler/index";
import {
  calculateAzimuth,
  calculateMaidenhead,
  correctUSCountryName,
  createCallSignIndex,
  createSearchIndex,
  findBandAndModeForFrequency,
  getFormattedDate,
  getStateLongName,
  getStateShortCode,
} from "helpers/Utils";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createNewLogBookAction,
  deleteContactDataAction,
  editContactLogBookDataAction,
  fetchCallSignData,
  getAllContactDataAction,
  getAllPaginatedContactsWithPagination,
  getAllUserContacts,
  getContactLogBookDataAction,
  getLogBooksAction,
  getPaginatedContactsData,
  saveContactLogBookDataAction,
  selectEditContactModalData,
  setAllContactsData,
  setCallSignData,
  setContactsData,
  setSelectedLogBookAction,
  deleteLogBookAction,
  getContactLogBookForAdifExportAction,
  handleEditLogBook,
  getPaginatedLogbookContacts,
  setUserLogBooks,
  setAllPaginatedContactsData,
  fetchCountryDataGrid,
  getAddContactLogPopupSummary,
  setAddContactLogPopupSummary,
} from "store/Actions/logBook.actions";
import {
  getIsCallSignDataLoadingSelector,
  getIsFeaturedMemberLoadingSelector,
  getIsFetchingCallSignAvailabilitySelector,
  getIsLogBookLoadingSelector,
  getIsLogBookSavingLoadingSelector,
  getIsLogContactForAdifLoadingSelector,
} from "store/Reducers/loading.reducer";
import {
  getAddContactPopupSummarySelector,
  getAllContactsListPaginatedSelector,
  getAllContactsListSelector,
  getAllPaginatedLogBookContactsDataSelector,
  getAllPaginatedUserContactsDataSelector,
  getAllUserContactsDataSelector,
  getCallSignDataSelector,
  getEditContactModalDataSelector,
  getLogBookContactListSelector,
  getLogBookListSelector,
  getSavedLocationsSelector,
  getSelectedLogBookSelector,
  getWorkedUserCountriesSelector,
  getWorkedUserGridSquareSelector,
} from "store/Reducers/logBook.reducer";
import {
  getFeaturedMembersSelector,
  getUserProfileSelector,
} from "store/Reducers/profile.reducer";
import {
  CallSignLookUpData,
  LogBookContactModel,
  LogBookModel,
  UserProfile,
} from "types/Models";
import {
  EditLogBook,
  LogbookContactPaginationProp,
  NewLogBook,
} from "types/State";
import { db } from "firebase-config";
import { LogBookContactTable, LogBookTable } from "constants/Collections";
import { ModesChartColor } from "constants/ArrayVariables";
import { getCurrentDate, getCurrentUTCtime } from "helpers/Date/Time";
import { useLocation } from "react-router-dom";
import useSubscription from "./subscription.hook";
import {
  CoordinatesParams,
  handleCoordinatesLoadingStatus,
  saveCoordinates,
} from "store/Actions/profile.actions";
import { fetchCoordinates } from "helpers/Location/GeoLocation";
import useConfig from "./config.hook";
import { storeUserProfileDataByCallSign } from "store/Actions/guest.action";
import usePota from "./pota.hook";
import { Templates } from "constants/Config";
import { Timestamp } from "firebase/firestore";
import moment from "moment";

const useLogBook = () => {
  const location = useLocation();
  const { handlePrimaryModal } = useConfig();

  const dispatch = useDispatch();
  const [searchTxt, setSearchTxt] = useState("");
  const logBookContacts = useSelector(getLogBookContactListSelector);
  const editContactModalData = useSelector(getEditContactModalDataSelector);
  const workedUserCountries = useSelector(getWorkedUserCountriesSelector);
  const workedUserGridSquare = useSelector(getWorkedUserGridSquareSelector);
  const savedLocations = useSelector(getSavedLocationsSelector);

  const { navigateToPricing } = useSubscription();

  const featuredMembers = useSelector(getFeaturedMembersSelector);
  const isFeaturedMembersLoading = useSelector(
    getIsFeaturedMemberLoadingSelector
  );

  const callSignLookUpLoading = useSelector(
    getIsFetchingCallSignAvailabilitySelector
  );
  const isCallSignDataLoading = useSelector(getIsCallSignDataLoadingSelector);
  const isLogContactForAdifLoading = useSelector(
    getIsLogContactForAdifLoadingSelector
  );
  const callSignData = useSelector(getCallSignDataSelector);
  const allUserContactsData = useSelector(getAllUserContactsDataSelector);
  const allPaginatedUserContactsData = useSelector(
    getAllPaginatedUserContactsDataSelector
  );

  const allPaginatedLogbookContacts = useSelector(
    getAllPaginatedLogBookContactsDataSelector
  );
  const addContactPopupSummary = useSelector(getAddContactPopupSummarySelector);

  const allContacts = useSelector(getAllContactsListSelector);
  const allPaginatedContacts: any = useSelector(
    getAllContactsListPaginatedSelector
  );
  const userProfile = useSelector(getUserProfileSelector);
  const [newLogBookName, setNewLogBookName] = useState<string>("");
  const [newLogBookTemplate, setNewLogBookTemplate] = useState<string>("");
  const isLoading = useSelector(getIsLogBookLoadingSelector);
  const isSavingOnLoading = useSelector(getIsLogBookSavingLoadingSelector);
  const logBooksList: LogBookModel[] = useSelector(getLogBookListSelector);
  const selectedLogBook = useSelector(getSelectedLogBookSelector);
  const [filter, setFilters] = useState({ mode: "" });
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedTabIndex, setSelectedTabIndex] = useState("entries");
  const [callSign, setCallSign] = useState("");
  const [time, setTime] = useState(getCurrentUTCtime);
  const [currentTime, setCurrentTime] = useState(getCurrentUTCtime);
  const [date, setDate] = useState(getCurrentDate);
  const [operator, setOperator] = useState("");
  const [qth, setQth] = useState("");
  const [rstSent, setRstSent] = useState("");
  const [rstRcvd, setRstRcvd] = useState("");
  const [notes, setNotes] = useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [grid, setGrid] = useState("");
  const [userFrequency, setUserFrequency] = useState("");
  const [userBand, setUserBand] = useState("");
  const [userQth, setUserQth] = useState("");
  const [userMode, setUserMode] = useState("");
  const [theirPark, setTheirPark] = useState("");

  const [power, setPower] = useState(selectedLogBook?.defaultPower);

  const [userCallSign, setUserCallSign] = useState(
    userProfile?.callSign ? userProfile?.callSign : ""
  );
  const [userGrid, setUserGrid] = useState(
    selectedLogBook?.defaultLocation?.gridsquare
      ? selectedLogBook?.defaultLocation?.gridsquare
      : userProfile?.gridSquare
      ? userProfile?.gridSquare
      : ""
  );
  const [myRadio, setMyRadio] = useState(
    selectedLogBook && selectedLogBook?.radio
      ? `${
          selectedLogBook?.radio?.name ||
          selectedLogBook?.radio?.brand + " " + selectedLogBook?.radio?.model ||
          ""
        }`
      : location?.state?.myRadio
      ? location?.state?.myRadio
      : ""
  );
  const [myAntenna, setMyAntenna] = useState(
    selectedLogBook && selectedLogBook?.antenna
      ? `${
          selectedLogBook?.antenna?.name ||
          selectedLogBook?.antenna?.brand +
            " " +
            selectedLogBook?.antenna?.model ||
          ""
        }`
      : location?.state?.myAntenna
      ? location?.state?.myAntenna
      : ""
  );
  const [modesData, setModesData] = useState([]);
  const [bandsData, setBandsData] = useState([]);
  const [bandsTotalAmount, setBandsTotalAmount] = useState(0);
  const [modesTotalAmount, setModesTotalAmount] = useState(0);

  const [userTimeInput, setUserTimeInput] = useState(null);
  const [userEditTimeInput, setUserEditTimeInput] = useState(null);

  const [selectedSecondaryField, setSelectedSecondaryField] = useState(-1);

  const storeLastContactsRef = useRef<any>(
    allContacts && allContacts.slice(0, 5) ? allContacts.slice(0, 5) : []
  );

  const { parkReferenceData, setParReferenceSuggestions, setParkByReference } =
    usePota();

  const getUserFromHamApiData = (
    callSign: string,
    setValues: any,
    setUserFrequency?: any
  ) => {
    dispatch(fetchCallSignData(callSign, setValues, setUserBand, setUserMode));
  };
  const getUserCountryFromGrid = (grid: string, setValues: any) => {
    dispatch(fetchCountryDataGrid(grid, setValues));
  };

  const setEditContactModalData = (contactData: LogBookContactModel | null) => {
    dispatch(selectEditContactModalData(contactData));
  };

  const handleRealTimeChange = (setValues: any) => {
    setValues();
  };
  const [stopRealTimeChange, setStopRealTimeChange] = useState(false);

  const handleModes = (userProfile: UserProfile) => {
    if (userProfile?.modes) {
      let arr: any = [];
      let modesTotal: any = 0;
      Object.entries(userProfile?.modes).forEach(([key, value]: any, index) => {
        if (value) {
          modesTotal = value + modesTotal;
        }
      });
      Object.entries(userProfile?.modes).forEach(([key, value]: any, index) => {
        let mode = {
          title: value,
          value: (100 * value) / modesTotal,
          color: ModesChartColor[index],
          key: key.toUpperCase(),
        };
        arr.push(mode);
      });
      setModesTotalAmount(modesTotal);
      setModesData(arr);
    }
    if (userProfile?.bands) {
      const cleanedBandData = Object.keys(userProfile?.bands).reduce(
        (acc: any, key) => {
          // Normalize the key by removing 'm' or 'M' and ensuring it's uppercase
          const normalizedKey = key.toUpperCase().replace("M", "");

          // Aggregate the values
          if (acc[normalizedKey]) {
            acc[normalizedKey] += userProfile?.bands[key];
          } else {
            acc[normalizedKey] = userProfile?.bands[key];
          }

          return acc;
        },
        {}
      );
      let arr: any = [];
      let bandsTotal: any = 0;
      Object.entries(cleanedBandData).forEach(([key, value]: any, index) => {
        bandsTotal = value + bandsTotal;
      });
      Object.entries(cleanedBandData).forEach(([key, value]: any, index) => {
        let mode = {
          title: `${value}` + ` QSO's`,
          value: (100 * value) / bandsTotal,
          color: ModesChartColor[index],
          key: key,
        };
        arr.push(mode);
      });
      setBandsTotalAmount(bandsTotal);
      setBandsData(arr);
    }
  };
  const handleClearAddContactForm = (
    initialData: any,
    setTouched: any,
    setValues: any,
    full?: boolean
  ) => {
    const contactTime = userTimeInput ? time : currentTime;
    if (!isDateTimeInFuture(date, contactTime)) {
      setValues((prev: any) => {
        const { frequency, userMode, band, power } = prev;
        setUserBand(band);
        setUserMode(userMode);
        setPower(power || selectedLogBook?.defaultPower || "");
        return full
          ? { ...initialData }
          : { ...initialData, frequency, userMode, band, power };
      });

      if (full) {
        setUserBand(initialData.band);
        setUserMode(initialData.userMode);
        setPower("");
      }

      setCountry(initialData.country);
      setUserCallSign(initialData.myCallSign);

      setUserGrid(
        selectedLogBook?.defaultLocation?.gridsquare
          ? selectedLogBook?.defaultLocation?.gridsquare
          : userProfile?.gridSquare || ""
      );
      setUserTimeInput(null);
      setTouched({});
      setTheirPark("");
      setCallSignUserData(null);
      setTime(getCurrentUTCtime);
      setDate(getCurrentDate);
      // clear state specific to callsign summary popup
      saveAddContactLogPopupSummary(null);
      dispatch(storeUserProfileDataByCallSign(null));
      setCallSign("");

      //clear pota data
      setParReferenceSuggestions(null);
      setParkByReference(null);

      setSelectedSecondaryField(-1);
    }
  };

  // useEffect(() => {
  //   if (userProfile?.gridSquare) {
  //     console.log("SET IN EFFECT 2");

  //     setUserGrid(
  //       selectedLogBook?.gridSquare
  //         ? selectedLogBook.gridSquare
  //         : userProfile.gridSquare
  //     );
  //   }
  // }, [userProfile]);

  // useEffect(() => {
  //   if (selectedLogBook && selectedLogBook.gridSquare) {
  //     setUserGrid(selectedLogBook.gridSquare || "");
  //   }
  // }, [selectedLogBook]);

  const deleteLogBook = (logbookId: string) => {
    dispatch(deleteLogBookAction(logbookId));
  };

  const getAllUserContactsData = (uid: string) => {
    dispatch(getAllUserContacts(uid));
  };

  const getPaginatedContacts = (condition: any) => {
    dispatch(getPaginatedContactsData(condition));
  };

  const getPaginatedContactsWithPagination = (condition: any) => {
    dispatch(getAllPaginatedContactsWithPagination(condition));
  };

  const logbookCollectionListner = () => {
    const unsubscribe = onSnapshot(
      query(
        collection(db, LogBookContactTable),
        where("adiImported", "==", false),
        orderBy("contactTimeStamp", "desc"),
        limit(200)
      ),
      (snapshot) => {
        const allUpdatedContacts: LogBookContactModel[] = snapshot.docs.map(
          (doc) => {
            return {
              id: doc.id,
              ...doc.data(),
            };
          }
        );
        const recentFiveContacts = allUpdatedContacts.slice(0, 5);
        dispatch(setAllContactsData(allUpdatedContacts));
        storeLastContactsRef.current = recentFiveContacts;
      }
    );
    return unsubscribe;
  };

  const userLogbookListener = () => {
    if (selectedLogBook && selectedLogBook.id) {
      const unsubscribe = onSnapshot(
        doc(db, LogBookTable, selectedLogBook?.id!),
        (snapshot) => {
          if (snapshot.exists()) {
            dispatch(
              setSelectedLogBookAction({
                id: snapshot.id,
                ...snapshot.data(),
              })
            );
          }
        }
      );
      return unsubscribe;
    } else {
      return () => {};
    }
  };

  const userLogbookCollectionListner = () => {
    if (userProfile && userProfile?.uid) {
      const unsubscribe = onSnapshot(
        query(
          collection(db, LogBookTable),
          where("uid", "==", userProfile?.uid),
          orderBy("timestamp", "desc")
        ),
        (snapshot) => {
          // console.log("ALL LOGBOOK LISTENER userLogbookCollectionListner");
          const newLogBooks: LogBookModel[] = snapshot.docs.map((doc) => {
            return {
              id: doc.id,
              ...doc.data(),
            };
          });
          dispatch(setUserLogBooks(newLogBooks));
        }
      );
      return unsubscribe;
    }
  };

  const userLogbookCollectionListnerById = (logBookId: string) => {
    if (logBookId && userProfile && userProfile?.uid) {
      let q = query(
        collection(db, LogBookContactTable),
        where("uid", "==", userProfile?.uid),
        where("logBookId", "==", logBookId),
        orderBy("contactTimeStamp", "desc"),
        limit(50)
      );
      // if (allPaginatedLogbookContacts?.firstDoc["timestamp"]) {
      //   q = query(
      //     q,
      //     startAt(allPaginatedLogbookContacts?.firstDoc["timestamp"])
      //   );
      // }
      const unsubscribe = onSnapshot(q, (snapshot) => {
        // console.log("ALL LOGBOOK LISTENER userLogbookCollectionListnerById");
        const newLogBooks: LogBookContactModel[] = snapshot.docs.map((doc) => {
          return {
            id: doc.id,
            ...doc.data(),
          };
        });

        dispatch(
          setAllPaginatedContactsData({
            ...allPaginatedLogbookContacts,
            contacts: newLogBooks,
          })
        );
        // console.log(
        //   "allPaginatedLogbookContacts : ",
        //   allPaginatedLogbookContacts
        // );
        getPaginatedLogBookContactsData({
          logbookId: logBookId,
          // ...allPaginatedLogbookContacts,
          // move: 0,
          loading: false,
          // firstDoc: newLogBooks[0],
        });
        dispatch(setContactsData(newLogBooks));
      });
      return unsubscribe;
    }
  };

  const createNewLogBook = (props: LogBookModel) => {
    // if (newLogBookName && newLogBookTemplate) {
    if (props.name) {
      if (userProfile?.callSign) {
        props.defaultCallSign = userProfile?.callSign || "";
      }

      if (userProfile?.savedLocationId) {
        props.defaultLocation = {
          ...savedLocations.find(
            (location) => location.id === userProfile?.savedLocationId
          ),
          isCustom: false,
        } as any;
      }

      dispatch(createNewLogBookAction(props));
    } else {
      allErrorHandler("field not filled");
    }
  };

  const getLogBooks = () => {
    dispatch(getLogBooksAction());
  };

  const setSelectedLogBook = (item: NewLogBook) => {
    dispatch(setSelectedLogBookAction(item));
  };

  const prepareParksDataPayload = (values: LogBookContactModel) => {
    const myParks = selectedLogBook?.myParks;
    const theirParks = values?.theirParks;
    const [firstPark] = Object.values(myParks || {});
    const [firstTheirPark] = Object.values(theirParks || {});

    const myCoordinates = {
      latitude:
        firstPark?.latitude ||
        (selectedLogBook?.defaultLocation?.lat as number) ||
        0,
      longitude:
        firstPark?.longitude ||
        (selectedLogBook?.defaultLocation?.lng as number) ||
        0,
    };

    const theirCoordinates = {
      latitude: (firstTheirPark?.latitude ||
        values?.theirCoordinates?.latitude ||
        0) as any,
      longitude: (firstTheirPark?.longitude ||
        values?.theirCoordinates?.longitude ||
        0) as any,
    };

    const myLocation = {
      userGrid:
        firstPark?.grid6 ||
        selectedLogBook?.defaultLocation?.gridsquare ||
        values.userGrid,
      myState: getStateShortCode(
        firstPark?.locationName ||
          selectedLogBook?.defaultLocation?.state ||
          values.myState ||
          ""
      ),
      myStateLongName: getStateLongName(
        firstPark?.locationName ||
          selectedLogBook?.defaultLocation?.state ||
          values.myState ||
          ""
      ),
      myCountry:
        firstPark?.entityName ||
        selectedLogBook?.defaultLocation?.country ||
        values.myCountry,
    };

    const theirLocation = {
      grid: firstTheirPark?.grid6 || values.grid,
      state: getStateShortCode(
        firstTheirPark?.locationName || values.state || ""
      ),
      stateLongName: getStateLongName(
        firstTheirPark?.locationName || values.state || ""
      ),
      country: firstTheirPark?.entityName || values.country,
    };

    return {
      ...(Object.keys(myParks || {})?.length
        ? { myCoordinates, ...myLocation, myParks }
        : {}),
      ...(Object.keys(theirParks || {})?.length
        ? { theirParks: theirParks, theirCoordinates, ...theirLocation }
        : {}),
    };
  };

  const getLogbookData = () => {
    let name = "";
    if (selectedLogBook?.name) {
      name = selectedLogBook?.name;
    } else if (selectedLogBook?.logbookStyle === Templates.POTA) {
      name = `${selectedLogBook?.defaultCallSign} @ `;
      name += Object.keys(selectedLogBook?.myParks || {}).join(", ");
    }

    return {
      logBookId: selectedLogBook?.id ? selectedLogBook?.id : null,
      logBook: {
        name,
        timestamp: selectedLogBook?.timestamp || ("" as any),
        logbookStyle: selectedLogBook?.logbookStyle || "",
      },
    };
  };

  const getMyLocationDetails = () => {
    // my coordinates
    const lat =
      (selectedLogBook?.defaultLocation?.lat as number) ||
      (userProfile?.coordinates?.latitude as number);
    const lng =
      (selectedLogBook?.defaultLocation?.lng as number) ||
      (userProfile?.coordinates?.longitude as number);

    const userGrid =
      selectedLogBook?.defaultLocation?.gridsquare ||
      userProfile?.gridSquare ||
      "";

    const myCity =
      selectedLogBook?.defaultLocation?.city || userProfile?.city || "";

    const myState = getStateShortCode(
      selectedLogBook?.defaultLocation?.state || userProfile?.state || ""
    );

    const myStateLongName = getStateLongName(
      selectedLogBook?.defaultLocation?.state || userProfile?.state || ""
    );

    const myCountry =
      selectedLogBook?.defaultLocation?.country || userProfile?.country || "";

    return {
      ...(lat && lng
        ? {
            myCoordinates: {
              latitude: lat,
              longitude: lng,
            },
          }
        : {}),
      userGrid,
      myCity,
      myState,
      myStateLongName,
      myCountry,
      ...(selectedLogBook?.defaultLocation?.id
        ? { mySavedLocationId: selectedLogBook?.defaultLocation?.id }
        : {}),
    };
  };

  const createNameSearchIndex = (firstName: string, lastName: string) => {
    const firstSearchIndex = firstName
      ? createSearchIndex(firstName.toLowerCase())
      : [];
    const lastSearchIndex = lastName
      ? createSearchIndex(lastName.toLowerCase())
      : [];

    let fullNameSearchIndex: string[] = [];

    if (firstName && lastName) {
      const fullName = `${firstName} ${lastName}`;
      const startFrom =
        firstName && lastName ? firstName?.length + 2 : undefined;
      fullNameSearchIndex = startFrom
        ? createSearchIndex(fullName.toLowerCase().trim(), startFrom)
        : [];
    }

    const nameSearchIndex = [
      ...firstSearchIndex,
      ...lastSearchIndex,
      ...fullNameSearchIndex,
    ];
    return nameSearchIndex;
  };

  const prepareSaveLogBookContactPayload = (values: any) => {
    // name all props here that you don't want to send to the server
    const { myPark, theirParkKey, ...rest } = values;
    return rest;
  };

  const saveLogBookContact = async (
    values: LogBookContactModel,
    resetHandler: () => void
  ) => {
    const myCallSign = selectedLogBook?.defaultCallSign
      ? selectedLogBook?.defaultCallSign
      : userProfile?.callSign
      ? userProfile?.callSign
      : "";
    let contactData: LogBookContactModel = {
      ...values,
      ...getLogbookData(),
      country: correctUSCountryName(values.country || ""),
      state: getStateShortCode(values.state || ""),
      stateLongName: getStateLongName(values.state || ""),
      time: userTimeInput ? userTimeInput : currentTime,
      profileCallSign: myCallSign,
      myCallSign: myCallSign ? myCallSign : values.myCallSign,
      myProfilePic: userProfile?.profilePic ? userProfile?.profilePic : "",
      adiImported: false,
      myFlagCode: userProfile?.flagCode,
      myName: userProfile?.firstName
        ? `${userProfile.firstName} ${userProfile.lastName}`
        : "",
      callSignSearchIndex: createCallSignIndex(values.theirCallsign || ""),
      nameSearchIndex: createNameSearchIndex(values.theirName || "", ""),
      myNameSearchIndex: createNameSearchIndex(
        userProfile?.firstName || "",
        userProfile?.lastName || ""
      ),
      date: getFormattedDate(values.date),
      // my location details (logbook settings or user profile)
      ...getMyLocationDetails(),
      // parks data
      ...prepareParksDataPayload(values),
      tags: selectedLogBook?.logbookStyle
        ? [selectedLogBook?.logbookStyle]
        : [],
      ...(selectedLogBook?.radio?.id
        ? {
            myRadioId: selectedLogBook?.radio?.id,
            myRadio:
              selectedLogBook?.radio?.name ||
              selectedLogBook?.radio?.brand +
                " " +
                selectedLogBook?.radio?.model ||
              myRadio ||
              "",
          }
        : {}),
      ...(selectedLogBook?.antenna?.id
        ? {
            myAntennaId: selectedLogBook?.antenna?.id,
            myAntenna:
              selectedLogBook?.antenna?.name ||
              selectedLogBook?.antenna?.brand +
                " " +
                selectedLogBook?.antenna?.model ||
              myAntenna ||
              "",
          }
        : {}),
    };

    if (
      !contactData.myCoordinates?.latitude &&
      !contactData.myCoordinates?.longitude
    ) {
      contactData.myCoordinates =
        selectedLogBook?.coordinates || userProfile?.coordinates;
    }

    if (isDateTimeInFuture(contactData.date, contactData.time)) {
      allErrorHandler("future date");
    } else {
      const contactDataPayload = prepareSaveLogBookContactPayload(contactData);
      dispatch(saveContactLogBookDataAction(contactDataPayload, resetHandler));
    }
  };

  const isDateTimeInFuture = (date: any, time: any): boolean => {
    const combinedDateTime = new Date(`${date}T${time}Z`); // 'Z' denotes UTC time
    const dateNow = new Date(); // Current date and time in UTC

    // Comparing UTC time
    if (combinedDateTime.getTime() > dateNow.getTime()) {
      return true;
    } else {
      return false;
    }
  };

  const editLogBookContact = async (
    values: LogBookContactModel,
    prevValues: LogBookContactModel
  ) => {
    let contactData: LogBookContactModel = values;
    dispatch(editContactLogBookDataAction(contactData, prevValues));
  };

  const getLogbookContact = (data: any) => {
    if (data.logBookId) {
      dispatch(getContactLogBookDataAction(data));
    }
  };

  const getLogbookContactForAdifExport = (
    logbookId: string,
    options?: { parkReference?: string }
  ) => {

    if (true) {
      if (logbookId) {


        dispatch(getContactLogBookForAdifExportAction(logbookId, options));
      }
    } else {
      navigateToPricing();
    }
  };

  const deleteContactData = (
    contactId: string,
    contact: LogBookContactModel
  ) => {
    dispatch(
      deleteContactDataAction(contactId, selectedLogBook?.id || "", contact)
    );
  };

  const getAllContactData = () => {
    dispatch(getAllContactDataAction());
  };

  const setCallSignUserData = (data: CallSignLookUpData | null) => {
    dispatch(setCallSignData(data));
  };

  const onFrequencyBlur = (frequency: string, setValues: any) => {
    try {
      const freqNumber = parseFloat(frequency);
      const bandMode = findBandAndModeForFrequency(freqNumber);
      if (bandMode) {
        setValues((prev: any) => {
          return { ...prev, band: bandMode.band, userMode: bandMode.mode };
        });
        setUserBand(bandMode.band);
        setUserMode(bandMode.mode);
      } else {
        setUserBand("");
        setUserMode("");
      }
    } catch (e) {}
  };

  const editLogbook = (data: EditLogBook) => {
    dispatch(handleEditLogBook(data));
  };

  const getPaginatedLogBookContactsData = (
    conditions: LogbookContactPaginationProp
  ) => {
    dispatch(getPaginatedLogbookContacts(conditions));
  };

  const checkLocationPermission = async (logbookId: string) => {
    dispatch(handleCoordinatesLoadingStatus(true));
    const coor: CoordinatesParams = await fetchCoordinates();
    if (
      coor &&
      !userProfile?.coordinates?.latitude &&
      !userProfile?.coordinates?.longitude
    ) {
      dispatch(handleCoordinatesLoadingStatus(true));
      dispatch(saveCoordinates(coor));
    } else if (
      !coor &&
      !userProfile?.coordinates?.latitude &&
      !userProfile?.coordinates?.longitude
    ) {
      handlePrimaryModal({
        title:
          "We dont have access to your location data, you can give us permission by going to the top URL section of your app and granting us permission.",
        type: "Location Permission",
        onCancelPressed: () => handlePrimaryModal(null),
        onSubmitPressed: () => {
          handlePrimaryModal(null);
        },
        submitText: "Ok",
        cancelText: "Cancel",
      });
    }
    if (coor) {
      dispatch(handleCoordinatesLoadingStatus(true));
      dispatch(
        handleEditLogBook({
          newLogBookData: {
            coordinates: coor,
          },
          logBookId: logbookId,
        })
      );
      const gridSquare = calculateMaidenhead(coor.latitude, coor.longitude);
      if (gridSquare) {
        setUserGrid(gridSquare);
      }
    }
  };

  const fetchAddContactLogPopupSummary = (callSignArg: string) => {
    dispatch(getAddContactLogPopupSummary({ theirCallsign: callSignArg }));
  };

  const saveAddContactLogPopupSummary = (data: any) => {
    dispatch(setAddContactLogPopupSummary(data));
  };

  const getAddContactLogPopupSummaryData = () => {
    let azimuth = null;

    const myLat = userProfile?.coordinates?.latitude;
    const myLong = userProfile?.coordinates?.longitude;

    const firstName = (callSignData as any)?.hamdb?.callsign?.fname
      ? (callSignData as any)?.hamdb?.callsign?.fname
      : null;
    const lastName = (callSignData as any)?.hamdb?.callsign?.name
      ? (callSignData as any)?.hamdb?.callsign?.name
      : null;
    const theirLat = (callSignData as any)?.hamdb?.callsign?.lat
      ? Number((callSignData as any)?.hamdb?.callsign?.lat)
      : null;
    const theirlong = (callSignData as any)?.hamdb?.callsign?.lon
      ? Number((callSignData as any)?.hamdb?.callsign?.lon)
      : null;
    const theirCallsign = (callSignData as any)?.hamdb?.callsign?.call
      ? (callSignData as any)?.hamdb?.callsign?.call
      : callSign || null;
    const theirCountry = (callSignData as any)?.hamdb?.callsign?.country
      ? (callSignData as any)?.hamdb?.callsign?.country
      : null;
    const theirState = (callSignData as any)?.hamdb?.callsign?.state
      ? (callSignData as any)?.hamdb?.callsign?.state
      : null;

    if (myLat && myLong && theirLat && theirlong) {
      azimuth = calculateAzimuth(myLat, myLong, theirLat, theirlong, {
        units: "km",
      });
    }

    return {
      firstName,
      lastName,
      isCallSignMatch: callSignData !== "NOT_FOUND" && !!callSignData,
      distance: azimuth?.distance
        ? `${azimuth?.distance.toLocaleString(undefined, {
            minimumFractionDigits: 1,
            maximumFractionDigits: 1,
          })} KM`
        : undefined,
      direction: azimuth?.bearing ? azimuth?.bearing?.toFixed(0) : undefined,
      theirCallsign,
      theirCountry,
      theirState,
      ...(addContactPopupSummary ? addContactPopupSummary : {}),
    };
  };

  return {
    checkLocationPermission,
    isLoading,
    isSavingOnLoading,
    logBooksList,
    getLogBooks,
    newLogBookName,
    setNewLogBookName,
    createNewLogBook,
    newLogBookTemplate,
    setNewLogBookTemplate,
    modalOpen,
    setModalOpen,
    setSelectedLogBook,
    selectedTabIndex,
    setSelectedTabIndex,
    selectedLogBook,
    callSign,
    setCallSign,
    time,
    setTime,
    currentTime,
    setCurrentTime,
    date,
    setDate,
    operator,
    setOperator,
    qth,
    setQth,
    rstSent,
    setRstSent,
    rstRcvd,
    setRstRcvd,
    notes,
    setNotes,
    state,
    setState,
    country,
    setCountry,
    grid,
    setGrid,
    userFrequency,
    setUserFrequency,
    userBand,
    setUserBand,
    userQth,
    setUserQth,
    userMode,
    setUserMode,
    userCallSign,
    setUserCallSign,
    userGrid,
    setUserGrid,
    saveLogBookContact,
    getLogbookContact,
    logBookContacts,
    allContacts,
    searchTxt,
    setSearchTxt,
    onFrequencyBlur,
    getAllContactData,
    editLogBookContact,
    myRadio,
    myAntenna,
    deleteContactData,
    getUserFromHamApiData,
    callSignLookUpLoading,
    isCallSignDataLoading,
    callSignData,
    setCallSignUserData,
    logbookCollectionListner,
    editContactModalData,
    setEditContactModalData,
    allUserContactsData,
    handleModes,
    modesData,
    bandsData,
    bandsTotalAmount,
    modesTotalAmount,
    handleClearAddContactForm,
    workedUserCountries,
    workedUserGridSquare,
    handleRealTimeChange,
    stopRealTimeChange,
    setStopRealTimeChange,
    setUserTimeInput,
    userTimeInput,
    allPaginatedContacts,
    getPaginatedContacts,
    allPaginatedUserContactsData,
    filter,
    setFilters,
    getPaginatedContactsWithPagination,
    userLogbookCollectionListnerById,
    getAllUserContactsData,
    featuredMembers,
    isFeaturedMembersLoading,
    deleteLogBook,
    setMyRadio,
    setMyAntenna,
    getLogbookContactForAdifExport,
    isLogContactForAdifLoading,
    editLogbook,
    userLogbookListener,
    setUserEditTimeInput,
    userEditTimeInput,
    getPaginatedLogBookContactsData,
    allPaginatedLogbookContacts,
    userLogbookCollectionListner,
    getUserCountryFromGrid,
    power,
    setPower,
    getAddContactLogPopupSummaryData,
    fetchAddContactLogPopupSummary,
    saveAddContactLogPopupSummary,
    setTheirPark,
    theirPark,
    dispatch,
    selectedSecondaryField,
    setSelectedSecondaryField,
  };
};

export default useLogBook;
