import React, { useEffect, useState } from "react";
import InputWithButton from "components/Inputs/InputWithButton";
import TitledInput from "components/Inputs/TitledInput";
import TitledTimeInput from "components/Inputs/TitledTimeInput";
import TitledCountryPicker from "components/Inputs/TitledCountryPicker";
import DropDownTitled from "components/DropDown/DropDownTitled";
import { AllBandsOption, Modes } from "constants/ArrayVariables";
import TitledRegionPicker from "components/Inputs/TitledRegionPicker";
import BorderButtonSecondary from "components/Button/BorderButtonSecondary";
import TitledInputGradient from "components/Inputs/TitledInputGradient";
import { useSelector } from "react-redux";
import { getContestDetailsSelector } from "store/Reducers/contest.reducer";
import { useLocation, useSearchParams } from "react-router-dom";
import { showToast } from "helpers/Toast";
import { ToastTypes } from "types/Component";
import { useDebouncedCallback } from "use-debounce";
import { LocationMappings } from "constants/LocationMappings";
import {
  convertBandValue,
  getDxDataFromCountry,
  setTabIndexForTimeInputs,
} from "helpers/Utils";
import SearchCountryPicker from "components/Inputs/SearchCountryPicker";
import { SelectableButtonsGroup } from "components/SelectableButtonsGroup";
import Icons from "assets/Icons";
import { TheirParkField } from "pages/Dashboard/LogBook/EditContact/TheirParksField";

const DefaultWrl = ({
  setValues,
  values,
  errors,
  handleChange,
  touched,
  submitForm,
  initialData,
  setTouched,
  onLogPress,
  display,
  callSignBlurPopupHandler,
  userMode,
  setUserMode,
  userBand,
  setUserBand,
  setUserCallSign,
  selectedLogBook,
  isSavingOnLoading,
  date,
  setDate,
  setTime,
  currentTime,
  onFrequencyBlur,
  userGrid,
  myRadio,
  myAntenna,
  getUserFromHamApiData,
  isCallSignDataLoading,
  callSignData,
  handleClearAddContactForm,
  setStopRealTimeChange,
  userTimeInput,
  setUserTimeInput,
  setMyAntenna,
  setMyRadio,
  checkLocationPermission,
  getUserCountryFromGrid,
  power,
  setPower,
  setUserGrid,
  callSignRef,
  selectedSecondaryField,
  setSelectedSecondaryField,
}: any) => {
  const contestDetail = useSelector(getContestDetailsSelector);
  let [searchParams] = useSearchParams();
  const location = useLocation();

  useEffect(() => {
    setTabIndexForTimeInputs("10", "11", "12");
  }, []);

  const debouncedCallsignInformation = useDebouncedCallback((searchString) => {
    if (searchString) {
      getUserFromHamApiData(searchString, setValues, onFrequencyBlur);
    } else {
      handleClearAddContactForm(initialData, setTouched, setValues);
    }

    callSignBlurPopupHandler(searchString);
  }, 800);

  const theirCallSignChangeHandler = (e: any) => {
    const theirCallSign = e.target.value.toUpperCase().trim();
    setValues((prev: any) => {
      return {
        ...prev,
        theirCallsign: theirCallSign,
      };
    });
    debouncedCallsignInformation(theirCallSign);
  };

  const renderSecondaryFields = (values: any, setValues: any) => {
    if (selectedSecondaryField === 2) {
      return (
        <div className="flex items-start w-full gap-3">
          <TheirParkField {...{ values, setValues }} />
          <button
            type="button"
            className="flex items-center justify-center mb-[20px] p-2 bg-blue-600  h-[40px] w-[40px]"
            onClick={() => setSelectedSecondaryField(0)}
          >
            <img src={Icons.SendIcon} alt="" />
          </button>
        </div>
      );
    }

    return null;
  };

  return (
    <div className="w-full flex justify-between flex-wrap">
      <TitledInput
        // setText={handleChange}
        setText={theirCallSignChangeHandler}
        name="theirCallsign"
        title="Their Callsign"
        placeHolder="Their Callsign"
        tabindex={1}
        // onBlur={() => {
        //   if (values.theirCallsign) {
        //     getUserFromHamApiData(values.theirCallsign, setValues);
        //   }
        //   callSignBlurPopupHandler(values.theirCallsign);
        // }}
        status={
          callSignData !== "NOT_FOUND" && callSignData ? "Callsign Match" : null
        }
        statusBg={
          callSignData !== "NOT_FOUND" && callSignData
            ? "bg-gradient-to-r from-[#8A24CA] to-[#651FFF]"
            : "bg-gradient-to-r from-[#FD9D82] to-[#FF6F46]"
        }
        isLoading={isCallSignDataLoading}
        width="w-[48.5%] sm:w-[23.5%] md:w-[18.7%] xl:w-[13%]"
        value={values.theirCallsign}
        className={"mb-1.5"}
        error={
          errors?.theirCallsign && touched?.theirCallsign
            ? errors?.theirCallsign
            : undefined
        }
        containerClass={display ? "pointer-events-none" : ""}
        props={{ innerRef: callSignRef }}
      />
      <TitledInput
        setText={handleChange}
        name="rstSent"
        title="RST Sent"
        info='Signal report that you gave to the other operator. "59" is a good signal.'
        placeHolder="RST Sent"
        value={values.rstSent}
        tabindex={2}
        className={"mb-1"}
        width="w-[48.5%] sm:w-[23.5%] md:w-[18.7%] xl:w-[13%]"
        error={
          errors?.rstSent && touched?.rstSent ? errors?.rstSent : undefined
        }
        containerClass={display ? "pointer-events-none" : ""}
      />
      <TitledInput
        setText={handleChange}
        name="rstRCVD"
        title="RST RCVD"
        info='Signal report that you received from the other operator. "59" would be a good signal.'
        placeHolder="RST RCVD"
        value={values.rstRCVD}
        tabindex={3}
        className={"mb-1"}
        width="w-[48.5%] sm:w-[23.5%] md:w-[18.7%] xl:w-[13%]"
        error={
          errors?.rstRCVD && touched?.rstRCVD ? errors?.rstRCVD : undefined
        }
        containerClass={display ? "pointer-events-none" : ""}
      />
      <InputWithButton
        setText={handleChange}
        name="frequency"
        title="Frequency"
        info="Frequency on which you made the contact, you can get this from reading your radio."
        placeHolder="Frequency"
        onFieldBlur={() => onFrequencyBlur(values.frequency, setValues)}
        tabindex={4}
        width="w-[48.5%] sm:w-[23.5%] md:w-[18.7%] xl:w-[13%]"
        value={values.frequency}
        btnTxt="MHz"
        className={"mb-3"}
        fitBtn
        error={
          errors?.frequency && touched?.frequency
            ? errors?.frequency
            : undefined
        }
        containerClass={display ? "pointer-events-none" : ""}
      />
      <DropDownTitled
        onChange={(e) => {
          handleChange(e);
          setUserBand(e.target.value);
        }}
        name="band"
        info="The band you made the contact on."
        value={userBand ? userBand : ""}
        title="Band"
        placeHolder="Band"
        tabindex={5}
        className={"mb-1"}
        width="w-[48.5%] sm:w-[23.5%] md:w-[18.7%] xl:w-[13%]"
        lists={AllBandsOption}
        getTitle={(item) => convertBandValue(item.name, item.unit) + item.unit}
        getValue={(item) => item.name}
        error={errors?.band && touched?.band ? errors?.band : undefined}
        containerClass={display ? "pointer-events-none" : ""}
      />
      <DropDownTitled
        onChange={(e) => {
          handleChange(e);
          setUserMode(e.target.value);
        }}
        name="userMode"
        info="The mode of operation you are using on your radio."
        value={userMode ? userMode : ""}
        title="Mode"
        placeHolder="Mode"
        tabindex={6}
        className={"mb-1"}
        width="w-[48.5%] sm:w-[23.5%] md:w-[18.7%] xl:w-[13%]"
        lists={Modes}
        error={
          errors?.userMode && touched?.userMode ? errors?.userMode : undefined
        }
        containerClass={display ? "pointer-events-none" : ""}
      />
      <TitledInput
        setText={(e) => {
          const value = e.target.value;
          setPower(value.trim());
          setValues((prev: any) => {
            return { ...prev, power: value.trim() };
          });
        }}
        name="power"
        title="Power (W)"
        placeHolder="Power (W)"
        width="w-[48.5%] sm:w-[23.5%] md:w-[18.7%] xl:w-[13%]"
        value={power}
        className={"mb-3"}
        tabindex={7}
        error={errors?.power && touched?.power ? errors?.power : undefined}
        containerClass={display ? "pointer-events-none" : ""}
      />

      <SearchCountryPicker
        name="country"
        title="Their Country"
        info="Country of the other operator."
        tabindex={8}
        value={values?.country}
        countryCode={values?.countryCode}
        placeHolder="Country"
        dxccNumber={values?.dxccNumber || ""}
        width={
          values?.country && values?.country?.length
            ? "w-[48.5%] sm:w-[23.5%] md:w-[18.7%] xl:w-[13%]"
            : "w-[48.5%] sm:w-[23.5%] md:w-[18.7%] xl:w-[27.5%]"
        }
        containerClass={display ? "pointer-events-none" : ""}
        setText={(val) => {
          const dxccData = getDxDataFromCountry(val);
          setValues((prev: any) => {
            return {
              ...prev,
              country: val,
              grid: val !== values?.country ? "" : values?.grid,
              dxccNumber: dxccData?.dxccNumber || "",
              continent: dxccData?.continent || "",
              flagCode: dxccData?.flagCode || "",
              countryCode: dxccData?.countryCode || "",
              state: val !== values?.country ? "" : values?.state,
            };
          });
        }}
      />

      {values?.country && values?.country.length ? (
        <TitledRegionPicker
          setText={(val) => {
            setValues((prev: any) => {
              return {
                ...prev,
                state: val,
                grid: val !== values?.state ? "" : values?.grid,
              };
            });
          }}
          name="state"
          title="Their State"
          info="State of the other operator."
          placeHolder="State"
          tabindex={9}
          width="w-[48.5%] sm:w-[23.5%] md:w-[18.7%] xl:w-[13%]"
          className={"mb-1"}
          value={values?.state}
          error={errors?.state && touched?.state ? errors?.state : undefined}
          country={LocationMappings[`${values?.country}`] || values?.country}
          containerClass={display ? "pointer-events-none" : ""}
        />
      ) : null}
      <TitledTimeInput
        setText={(e) => {
          setStopRealTimeChange(true);
          setUserTimeInput(e);
          setTime(e);
          setValues((prev: any) => {
            return { ...prev, time: e };
          });
        }}
        name="time"
        title="Time (UTC)"
        placeHolder="00:00 AM"
        className={"mb-3"}
        width="w-[48.5%] sm:w-[23.5%] md:w-[18.7%] xl:w-[13%]"
        type="time"
        value={userTimeInput ? values?.time : currentTime}
        error={errors?.time && touched?.time ? errors?.time : undefined}
        containerClass={display ? "pointer-events-none" : ""}
      />
      <TitledInput
        setText={(e) => {
          setValues((prev: any) => {
            return { ...prev, date: e.target.value };
          });
          setDate(e.target.value);
        }}
        name="date"
        title="Date"
        placeHolder="dd/mm/yyyy"
        tabindex={13}
        width="w-[48.5%] sm:w-[23.5%] md:w-[18.7%] xl:w-[13%]"
        type="date"
        value={date}
        className={"mb-1"}
        error={errors?.date && touched?.date ? errors?.date : undefined}
        props={
          {
            // max: new Date().toJSON().slice(0, 10),
          }
        }
        containerClass={display ? "pointer-events-none" : ""}
      />
      <TitledInput
        setText={handleChange}
        name="grid"
        title="Their Grid"
        placeHolder="Their Grid"
        info="Special location of the other operator. If they are at a park, for instance, you can write the identifier of the park."
        tabindex={14}
        className={"mb-3"}
        value={values.grid}
        width={"w-[48.5%] sm:w-[23.5%] md:w-[18.7%] xl:w-[13%]"}
        error={errors?.grid && touched?.grid ? errors?.grid : undefined}
        onBlur={() => {
          if (values.grid) {
            getUserCountryFromGrid(values?.grid, setValues);
          }
        }}
        containerClass={display ? "pointer-events-none" : ""}
      />
      <TitledInput
        setText={handleChange}
        name="qth"
        title="Their City"
        info="Special location of the other operator. If they are at a park, for instance, you can write the identifier of the park."
        placeHolder="Qth"
        tabindex={15}
        width="w-[48.5%] sm:w-[23.5%] md:w-[18.7%] xl:w-[13%]"
        className={"mb-1"}
        value={values?.qth}
        error={errors?.qth && touched?.qth ? errors?.qth : undefined}
        containerClass={display ? "pointer-events-none" : ""}
      />
      <TitledInput
        setText={handleChange}
        name="theirName"
        title="Their Name"
        placeHolder="Their Name"
        width="w-[48.5%] sm:w-[23.5%] md:w-[18.7%] xl:w-[13%]"
        value={values.theirName}
        tabindex={16}
        className={"mb-3"}
        error={
          errors?.theirName && touched?.theirName
            ? errors?.theirName
            : undefined
        }
        containerClass={display ? "pointer-events-none" : ""}
      />

      {(searchParams.get("contest_id") || contestDetail) &&
      selectedLogBook?.contestId ? (
        <div className="flex flex-wrap gap-6 w-full items-center">
          {contestDetail?.exchangeOne || contestDetail?.exchangeTwo ? (
            <p className="w-full md:w-[30%] xl:w-[27.5%] text-white font-[Play] text-base font-bold">
              CONTEST EXCHANGE
            </p>
          ) : null}

          {contestDetail?.exchangeOne ? (
            <TitledInputGradient
              setText={handleChange}
              name="exchangeOne"
              title={contestDetail?.exchangeOne}
              placeHolder="Enter Answer"
              value={values.exchangeOne}
              width={"w-full sm:w-[47.5%] md:w-[30%] xl:w-[27.5%]"}
              className={"mb-3 placeholder-[#000]"}
              tabindex={17}
              error={undefined}
            />
          ) : null}
          {contestDetail?.exchangeTwo ? (
            <TitledInputGradient
              setText={handleChange}
              name="exchangeTwo"
              title={contestDetail?.exchangeTwo}
              placeHolder="Enter Answer"
              value={values.exchangeTwo}
              width={"w-full sm:w-[47.5%] md:w-[30%] xl:w-[27.5%]"}
              className={"mb-3 placeholder-[#000]"}
              tabindex={18}
              error={undefined}
            />
          ) : null}
        </div>
      ) : null}

      <TitledInput
        setText={handleChange}
        name="notes"
        title="Notes"
        placeHolder="Notes"
        value={values.notes}
        width={"w-full sm:w-[49%] md:w-[59.5%] xl:w-[71%]"}
        className={"mb-3"}
        tabindex={19}
        error={errors?.notes && touched?.notes ? errors?.notes : undefined}
        containerClass={display ? "pointer-events-none" : ""}
      />
      <div className="w-full sm:w-[49%] md:w-[39%] xl:w-[27.5%] flex justify-between mb-8">
        <div className="flex items-end w-[47.5%]" tabIndex={20}>
          <BorderButtonSecondary
            isLoading={isSavingOnLoading}
            text={"Log Contact"}
            onClick={() => {
              // setUserBand("");
              // setUserMode("");
              // setDate(getCurrentDate());
              onLogPress && onLogPress();
            }}
            childClassName="text-sm"
            className={display ? "pointer-events-none" : ""}
          />
        </div>

        <div className="flex items-end w-[47.5%]" tabIndex={21}>
          <BorderButtonSecondary
            isLoading={false}
            text={"Clear"}
            onClick={() =>
              handleClearAddContactForm(
                initialData,
                setTouched,
                setValues,
                true
              )
            }
            childClassName="text-sm"
            className={display ? "pointer-events-none" : ""}
          />
        </div>
      </div>
      <SelectableButtonsGroup
        initialSelected={selectedSecondaryField}
        toggle
        actions={[
          {
            id: 2,
            icon: Icons.parkWhite,
            label: "Their Park Number",
            tabIndex: 9,
          },
        ]}
        onButtonChange={(action) => {
          if (action && (action.id as number) > 0) {
            setSelectedSecondaryField(action.id as number);
          } else {
            setSelectedSecondaryField(-1);
          }
        }}
      />
      {selectedSecondaryField > 0 ? (
        <div className="flex w-full bg-indigo-500 bg-opacity-10  mt-4 items-center p-4">
          {renderSecondaryFields(values, setValues)}
        </div>
      ) : null}
    </div>
  );
};

export default DefaultWrl;
