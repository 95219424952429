import Header from "components/Header";
import AddLogBookWrapper from "components/LogBook/AddLogBookWrapper";
import React, { useEffect, useRef, useState } from "react";
import Entries from "components/Entries";
import InputWithButton from "components/Inputs/InputWithButton";
import TitledInput from "components/Inputs/TitledInput";
import ContactMap from "components/Map/ContactMap";
import TopTabNav from "components/TopTab/TopTabNav";
import useLogBook from "hooks/logBook.hook";
import { Formik, Form } from "formik";
import CustomValidation from "helpers/Validation";
import TitledTimeInput from "components/Inputs/TitledTimeInput";
import TitledCountryPicker from "components/Inputs/TitledCountryPicker";
import DropDownTitled from "components/DropDown/DropDownTitled";
import { Modes } from "constants/ArrayVariables";
import TitledRegionPicker from "components/Inputs/TitledRegionPicker";
import BorderButtonSecondary from "components/Button/BorderButtonSecondary";
import useProfile from "hooks/profile.hook";
import { globalNavigate } from "routes/GlobalRoutes";
import { LOG_CONTACT_ROUTE } from "constants/Links";
import ButtonRounded from "components/Button/ButtonRounded";
import { getCurrentUTCtime } from "helpers/Date/Time";
import { useLocation, useSearchParams } from "react-router-dom";
import TitledInputGradient from "components/Inputs/TitledInputGradient";
import { useDispatch, useSelector } from "react-redux";
import { getUserContestRanking } from "store/Actions/contest.actions";
import {
  getContestDetailsSelector,
  getUserContestRankingSelector,
} from "store/Reducers/contest.reducer";
import {
  fetchLogbookDetails,
  handleEditLogBook,
} from "store/Actions/logBook.actions";
import {
  findStateName,
  getLogBookErrorMessages,
  getLogbookErrorColors,
} from "helpers/Utils";
import ContactSpotting from "./ContactSpotting";
import { fetchSpot } from "store/Actions/spotting.action";
import { showToast } from "helpers/Toast";
import { ToastTypes } from "types/Component";
import DefaultWrl from "components/LogbookTemplates/DefaultWrl";
import { Templates } from "constants/Config";
import N4MLLog from "components/LogbookTemplates/N4MLLog";
import POTALog from "components/LogbookTemplates/POTALog";
import { storeUserProfileDataByCallSign } from "store/Actions/guest.action";
import { AddContactLogPopup } from "./AddContactLogPopup";
import usePota from "hooks/pota.hook";
import { LogBookStats } from "./LogBookStats";
import { LogBookQuickSettings } from "./LogBookQuickSettings";
import { useSortAndFilter } from "hooks/useSortAndFilter";
var isEqual = require("lodash.isequal");
function LogContacts() {
  const {
    getCoordinatesListener,
    isGettingLocationIsLoading,
    userProfile,
    fetchCallSignProfileData,
    guestUserProfile,
  } = useProfile();
  const {
    userCallSign,
    setUserGrid,
    setUserCallSign,
    selectedTabIndex,
    setSelectedTabIndex,
    selectedLogBook,
    saveLogBookContact,
    isSavingOnLoading,
    date,
    setDate,
    time,
    setTime,
    currentTime,
    setCurrentTime,
    userMode,
    setUserMode,
    onFrequencyBlur,
    userBand,
    setUserBand,
    userGrid,
    myRadio,
    myAntenna,
    getUserFromHamApiData,
    isCallSignDataLoading,
    callSignData,
    setCallSignUserData,
    handleClearAddContactForm,
    setStopRealTimeChange,
    userTimeInput,
    setUserTimeInput,
    userLogbookCollectionListnerById,
    setMyAntenna,
    setMyRadio,
    editLogbook,
    getPaginatedLogBookContactsData,
    allPaginatedLogbookContacts,
    isLoading,
    checkLocationPermission,
    getUserCountryFromGrid,
    getAddContactLogPopupSummaryData,
    fetchAddContactLogPopupSummary,
    saveAddContactLogPopupSummary,
    setCallSign,
    power,
    setPower,
    theirPark,
    setTheirPark,
    userLogbookListener,
    selectedSecondaryField,
    setSelectedSecondaryField,
  } = useLogBook();
  const dispatch = useDispatch();
  useEffect(() => {
    const unsbscribe = userLogbookListener();
    return () => {
      if (unsbscribe) {
        unsbscribe();
      }
    };
  }, []);

  const addContactLogPopupSummary = getAddContactLogPopupSummaryData();

  const userContestRanking = useSelector(getUserContestRankingSelector);
  const contestDetail = useSelector(getContestDetailsSelector);
  let [searchParams, setSearchParams] = useSearchParams();
  const [selectedIndex, setSelectedIndex] = useState<string | null>("");

  const formikRef = React.useRef<any>();
  const callSignRef = React.useRef<any>();
  const location = useLocation();

  const { setParkByReference, setParReferenceSuggestions } = usePota();

  const {
    setSortConfig,
    filters,
    setFilters,
    applyChanges,

    filterCountry,
    setFilterCountry,
    filterRegion,
    setFilterRegion,
    filterBand,
    setFilterBand,
    filterMode,
    setFilterMode,
    spottedCountry,
    setSpottedCountry,
    spottedRegion,
    setSpottedRegion,
    operatingNearMe,
    setOperatingNearMe,
    dxOnly,
    setDxOnly,
    spottedNearMe,
    setSpottedNearMe,
    potaOnly,
    setPotaOnly,
    wrlOnly,
    setWrlOnly,
    logged,
    setLogged,
  } = useSortAndFilter({ column: "", order: "" }, []);

  const initialData = {
    theirCallsign: "",
    time: time,
    date: date,
    operator: "",
    qth: "",
    rstSent: "59",
    rstRCVD: "59",
    notes: "",
    state: "",
    country: "",
    grid: "",
    frequency: "",
    band: "",
    userQth: "",
    userMode: "",
    myCallSign: userCallSign,
    userGrid: selectedLogBook?.defaultLocation
      ? selectedLogBook?.defaultLocation?.gridsquare || userGrid
      : userGrid,
    power: selectedLogBook?.defaultPower || "",
    theirName: "",
    myRadio: myRadio ? myRadio : "",
    myAntenna: myAntenna ? myAntenna : "",
    adiImported: false,
    contestId:
      searchParams.get("contest_id") || selectedLogBook?.contestId || "",
    exchangeOne: "",
    exchangeTwo: "",
    myPark: "",
    theirPark: "",
    dxccNumber: 0,
    continent: "",
    flagCode: "",
    countryCode: "",
    myRadioId: "",
    myAntennaId: "",
    mySavedLocationId: "",
  };

  //issue here is the useEffect is being executed for changes on location while we only need the changes inside location.state?.spotData
  // resulting in the useEffect being executed multiple times, we need to add a condition to check if the spotData has changed
  const prevSpotDataRef = useRef(null);
  useEffect(() => {
    if (!isEqual(prevSpotDataRef.current, location.state?.spotData)) {
      setTimeout(() => {
        if (location.state?.spotData) {
          const item = location.state?.spotData;
          if (formikRef.current) {
            formikRef.current.setValues((prev: any) => {
              let data = {
                ...prev,
              };
              const stateName = findStateName(
                item?.theirCountry,
                item?.theirState
              );
              data.theirCallsign = item.theirCallSign || "";
              data.operator = item.theirName || "";
              data.state = stateName || item.theirState || "";
              data.country = item.theirCountry || "";
              data.grid = item.theirGrid || "";
              data.frequency = item.frequency || "";
              data.band = item.band || "";
              data.userMode = item.mode || "";
              data.theirName = item.theirName || "";
              data.theirPark = item.parkNumber || "";
              data.spotSource = item.spotSource || "";
              data.spottedContact = true;
              data.qth = item.parkNumber || "";
              data.theirParkKey = item.parkNumber || "";
              return data;
            });
            setTheirPark(item.parkNumber || "");
            setUserBand(item.band || "");
            setUserMode(item.mode || "");
            showToast({
              message: "Spotting data copied to logbook",
              type: ToastTypes.SUCCESS,
            });
          } else {
          }
        }
      }, 1000);
      // Update the reference to the current spotData
      prevSpotDataRef.current = location.state?.spotData;
    }
  }, [location.state?.spotData]);

  useEffect(() => {
    if (userMode === "CW" || userMode === "RTTY") {
      formikRef.current.setValues((prev: any) => {
        let data = {
          ...prev,
          rstSent: "599",
          rstRCVD: "599",
        };
        return data;
      });
    } else {
      formikRef.current.setValues((prev: any) => {
        let data = {
          ...prev,
          rstSent: "59",
          rstRCVD: "59",
        };
        return data;
      });
    }
  }, [userMode]);

  useEffect(() => {
    dispatch(fetchSpot([]));
    dispatch(storeUserProfileDataByCallSign(null));
    saveAddContactLogPopupSummary(null);
    setParkByReference(null);
    setParReferenceSuggestions(null);

    // focus on callsign field, when component is mounted
    callSignRef?.current?.focus();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let resetHandler: any = function () {};

  useEffect(() => {
    setSelectedTabIndex(
      searchParams.get("contact-tab")
        ? searchParams.get("contact-tab")!
        : "entries"
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  useEffect(() => {
    setCallSignUserData(null);
    if (searchParams.get("logbook-id")) {
      getCoordinatesListener();
      // getLogbookContact(searchParams.get("logbook-id")!);
      getPaginatedLogBookContactsData({
        logbookId: searchParams.get("logbook-id")!,
      });
    } else {
      globalNavigate(LOG_CONTACT_ROUTE);
    }
    const timeInterval = setInterval(() => {
      setCurrentTime(getCurrentUTCtime);
    }, 1000);

    return () => {
      clearInterval(timeInterval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const userLogbookUnsubscribe = userLogbookCollectionListnerById(
      searchParams.get("logbook-id")!
    );

    return () => {
      if (userLogbookUnsubscribe) {
        userLogbookUnsubscribe();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (searchParams.get("contest_id")) {
      dispatch(fetchLogbookDetails(selectedLogBook?.id || ""));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (searchParams.get("contest_id") && selectedLogBook?.contactCount) {
      dispatch(
        getUserContestRanking({
          contestId: searchParams.get("contest_id")!,
          userId: userProfile?.uid || "",
          contactCount: selectedLogBook?.contactCount,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLogBook?.contactCount]);

  useEffect(() => {
    if (filters.length > 0) {
      applyChanges(filters);
    }
  }, [allPaginatedLogbookContacts]);

  const onUpgradeClick = () => {
    globalNavigate("/membership");
  };

  const onCancelClick = () => {
    editLogbook({
      newLogBookData: {
        errorCode: null,
      },
      logBookId: searchParams.get("logbook-id") || selectedLogBook?.id || "",
    });
  };
  const callSignBlurPopupHandler = (callSignArg: string) => {
    // clear previous data
    setCallSignUserData(null);
    saveAddContactLogPopupSummary(null);
    dispatch(storeUserProfileDataByCallSign(null));

    setCallSign(callSignArg);

    if (callSignArg) {
      // fetch new data
      fetchCallSignProfileData(callSignArg);
      fetchAddContactLogPopupSummary(callSignArg);
    }
  };
  const getLogbookImportError = () => {
    if (selectedLogBook?.adiFile) {
      if (selectedLogBook.importStatus) {
        if (selectedLogBook.errorCode) {
          return (
            <div
              className={`w-[100%] md:w-[50%] ${
                getLogbookErrorColors(selectedLogBook.errorCode)[0]
              } px-4 py-2 rounded-md mb-6`}
            >
              <p
                className={`${
                  getLogbookErrorColors(selectedLogBook.errorCode)[1]
                } font-[Barlow] font-semibold`}
              >
                Error importing contacts.
              </p>
              <p
                className={`${
                  getLogbookErrorColors(selectedLogBook.errorCode)[2]
                } font-[Barlow]`}
              >
                {getLogBookErrorMessages(selectedLogBook.errorCode)}
              </p>
              <div className="flex justify-end">
                <button
                  className={`mr-10 ${
                    getLogbookErrorColors(selectedLogBook.errorCode)[1]
                  }`}
                  onClick={onCancelClick}
                >
                  Cancel
                </button>
                {selectedLogBook.errorCode === "QUOTA_EXCEEDED" ? (
                  <button className="text-[#166434]" onClick={onUpgradeClick}>
                    Upgrade
                  </button>
                ) : null}
              </div>
            </div>
          );
        }
      } else {
        return null;
      }
    } else {
      return null;
    }
  };

  const getTabLayout = () => {
    if (selectedTabIndex === "entries") {
      return (
        <Entries
          lists={
            allPaginatedLogbookContacts && allPaginatedLogbookContacts.contacts
              ? allPaginatedLogbookContacts.contacts
              : []
          }
          editable={true}
          pagination={true}
          missingFields={
            selectedLogBook?.importStatus === "Complete With Errors"
          }
          paginateContact={getPaginatedLogBookContactsData}
          paginatedData={allPaginatedLogbookContacts}
          logbookId={searchParams.get("logbook-id") || ""}
          isLoading={isLoading}
          userProfile={userProfile}
          showSort={true}
          searchable
          showExchange={!!selectedLogBook?.contestId}
          exchangeOne={contestDetail?.exchangeOne || ""}
          exchangeTwo={contestDetail?.exchangeTwo || ""}
        />
      );
    } else if (selectedTabIndex === "qso") {
      return (
        <ContactMap
          className="w-full h-[500px] relative"
          containerClassName="w-full"
          showControls={true}
          showShare={true}
        />
      );
    } else if (selectedTabIndex === "spotting") {
      return (
        <ContactSpotting
          setValues={formikRef.current ? formikRef.current.setValues : null}
          setMode={setUserMode}
          setBand={setUserBand}
          setTheirPark={setTheirPark}
          setSortConfig={setSortConfig}
          filters={filters}
          setFilters={setFilters}
          applyChanges={applyChanges}
          filterCountry={filterCountry}
          setFilterCountry={setFilterCountry}
          filterRegion={filterRegion}
          setFilterRegion={setFilterRegion}
          filterBand={filterBand}
          setFilterBand={setFilterBand}
          filterMode={filterMode}
          setFilterMode={setFilterMode}
          spottedCountry={spottedCountry}
          setSpottedCountry={setSpottedCountry}
          spottedRegion={spottedRegion}
          setSpottedRegion={setSpottedRegion}
          operatingNearMe={operatingNearMe}
          setOperatingNearMe={setOperatingNearMe}
          dxOnly={dxOnly}
          setDxOnly={setDxOnly}
          spottedNearMe={spottedNearMe}
          setSpottedNearMe={setSpottedNearMe}
          potaOnly={potaOnly}
          setPotaOnly={setPotaOnly}
          wrlOnly={wrlOnly}
          setWrlOnly={setWrlOnly}
          logged={logged}
          setLogged={setLogged}
        />
      );
    }
  };

  const getLoggerLayout = (
    setValues: any,
    values: any,
    errors: any,
    handleChange: any,
    touched: any,
    submitForm: any,
    initialData: any,
    setTouched: any,
    onLogPress: any
  ) => {
    if (selectedLogBook?.logbookStyle === Templates.N4ML) {
      return (
        <N4MLLog
          setValues={setValues}
          values={values}
          errors={errors}
          handleChange={handleChange}
          touched={touched}
          submitForm={submitForm}
          initialData={initialData}
          setTouched={setTouched}
          onLogPress={onLogPress}
          callSignBlurPopupHandler={callSignBlurPopupHandler}
          userMode={userMode}
          setUserMode={setUserMode}
          setUserBand={setUserBand}
          selectedLogBook={selectedLogBook}
          isSavingOnLoading={isSavingOnLoading}
          date={date}
          setDate={setDate}
          setTime={setTime}
          currentTime={currentTime}
          onFrequencyBlur={onFrequencyBlur}
          getUserFromHamApiData={getUserFromHamApiData}
          isCallSignDataLoading={isCallSignDataLoading}
          callSignData={callSignData}
          handleClearAddContactForm={handleClearAddContactForm}
          setStopRealTimeChange={setStopRealTimeChange}
          userTimeInput={userTimeInput}
          setUserTimeInput={setUserTimeInput}
          checkLocationPermission={checkLocationPermission}
          callSignRef={callSignRef}
          {...{ selectedSecondaryField, setSelectedSecondaryField }}
        />
      );
    } else if (selectedLogBook?.logbookStyle === Templates.POTA) {
      return (
        <POTALog
          setValues={setValues}
          values={values}
          errors={errors}
          handleChange={handleChange}
          touched={touched}
          submitForm={submitForm}
          initialData={initialData}
          setTouched={setTouched}
          onLogPress={onLogPress}
          callSignBlurPopupHandler={callSignBlurPopupHandler}
          userMode={userMode}
          setUserMode={setUserMode}
          userBand={userBand}
          setUserBand={setUserBand}
          setUserCallSign={setUserCallSign}
          selectedLogBook={selectedLogBook}
          isSavingOnLoading={isSavingOnLoading}
          date={date}
          setDate={setDate}
          setTime={setTime}
          currentTime={currentTime}
          getUserFromHamApiData={getUserFromHamApiData}
          isCallSignDataLoading={isCallSignDataLoading}
          callSignData={callSignData}
          handleClearAddContactForm={handleClearAddContactForm}
          setStopRealTimeChange={setStopRealTimeChange}
          userTimeInput={userTimeInput}
          setUserTimeInput={setUserTimeInput}
          checkLocationPermission={checkLocationPermission}
          setTheirPark={setTheirPark}
          theirPark={theirPark}
          callSignRef={callSignRef}
          getUserCountryFromGrid={getUserCountryFromGrid}
          {...{ selectedSecondaryField, setSelectedSecondaryField }}
        />
      );
    } else {
      return (
        <DefaultWrl
          setValues={setValues}
          values={values}
          errors={errors}
          handleChange={handleChange}
          touched={touched}
          submitForm={submitForm}
          initialData={initialData}
          setTouched={setTouched}
          onLogPress={onLogPress}
          callSignBlurPopupHandler={callSignBlurPopupHandler}
          userMode={userMode}
          setUserMode={setUserMode}
          userBand={userBand}
          setUserBand={setUserBand}
          setUserCallSign={setUserCallSign}
          selectedLogBook={selectedLogBook}
          isSavingOnLoading={isSavingOnLoading}
          date={date}
          setDate={setDate}
          setTime={setTime}
          currentTime={currentTime}
          onFrequencyBlur={onFrequencyBlur}
          userGrid={userGrid}
          myRadio={myRadio}
          myAntenna={myAntenna}
          getUserFromHamApiData={getUserFromHamApiData}
          isCallSignDataLoading={isCallSignDataLoading}
          callSignData={callSignData}
          handleClearAddContactForm={handleClearAddContactForm}
          setStopRealTimeChange={setStopRealTimeChange}
          userTimeInput={userTimeInput}
          setUserTimeInput={setUserTimeInput}
          setMyAntenna={setMyAntenna}
          setMyRadio={setMyRadio}
          checkLocationPermission={checkLocationPermission}
          getUserCountryFromGrid={getUserCountryFromGrid}
          power={power}
          setPower={setPower}
          setUserGrid={setUserGrid}
          callSignRef={callSignRef}
          {...{ selectedSecondaryField, setSelectedSecondaryField }}
        />
      );
    }
  };

  return (
    <>
      {getLogbookImportError()}
      <LogBookQuickSettings />
      <Formik
        initialValues={initialData}
        validationSchema={
          selectedLogBook?.logbookStyle === Templates.POTA
            ? CustomValidation.AddPOTAContactValidationSchema
            : CustomValidation.AddContactValidationSchema
        }
        onSubmit={async (values, { setSubmitting, setTouched, setValues }) => {
          const cb = () => {
            callSignRef?.current?.focus();
            resetHandler(initialData, setTouched, setValues);
          };
          saveLogBookContact(values, cb);
        }}
        innerRef={formikRef}
      >
        {({
          errors,
          touched,
          resetForm,
          handleChange,
          submitForm,
          values,
          setValues,
          setTouched,
        }) => {
          const onLogPress = () => {
            resetHandler = handleClearAddContactForm;
            submitForm();
          };
          const handleKeyDown = (event: any) => {
            if (event.key === "Enter") {
              event.preventDefault();

              onLogPress();
            }
          };
          return (
            <Form
              className="flex flex-col md:flex-row justify-between w-full bg-[#151541] px-3 py-5"
              onKeyDown={handleKeyDown}
            >
              {getLoggerLayout(
                setValues,
                values,
                errors,
                handleChange,
                touched,
                submitForm,
                initialData,
                setTouched,
                onLogPress
              )}
            </Form>
          );
        }}
      </Formik>
      <AddContactLogPopup
        data={{
          addContactLogPopupSummary,
          userProfile: guestUserProfile,
          theirCallSign: formikRef?.current?.values?.theirCallsign || "",
          flagCode: formikRef?.current?.values?.flagCode || "",
        }}
      />

      {/* LOGBOOK STATS */}
      <LogBookStats />

      {searchParams.get("contest_id") ? (
        <div className="flex flex-col sm:flex-row  justify-between w-full mt-4">
          <div className="font-[Play] text-white text-lg font-bold mb-3">
            Total Points:{" "}
            <span className="text-[#FF6F46]">
              {selectedLogBook?.contactCount || 0}
            </span>
          </div>
          <div className="font-[Play] text-white text-lg font-bold mb-3">
            Total QSO:{" "}
            <span className="text-[#FF6F46]">
              {selectedLogBook?.contactCount || 0}
            </span>
          </div>
          <div className="font-[Play] text-white text-lg font-bold mb-3">
            Current Ranking:{" "}
            <span className="text-[#FF6F46]">{userContestRanking}</span>
          </div>
        </div>
      ) : null}
      <TopTabNav
        setSelectedTabIndex={(tab) => {
          const newSearchParams = new URLSearchParams(searchParams);
          newSearchParams.set("contact-tab", tab);
          setSearchParams(newSearchParams);
        }}
        selectedTabIndex={selectedTabIndex}
        firstIndexText={`ENTRIES(${selectedLogBook?.contactCount || 0})`}
        secondIndexText={`QSO Map`}
        thirdIndexText={`Spotting`}
      />
      {getTabLayout()}
    </>
  );
}

export default LogContacts;
