import { useCallback, useState } from "react";
import Icons from "assets/Icons";
import Header from "components/Header";

import { useDispatch, useSelector } from "react-redux";
import CurrentUTCTime from "components/CurrentUTCTime";
import { spottingBandList, spottingModes } from "constants/Config";

import SpottingMap from "components/GoogleMap/SpottingMap";
import SpottingTableView from "components/SpottingTableView";
import SpottingGridView from "components/SpottingGridView";
import { getSpotSelector } from "store/Reducers/spotting.reducer";
import { getIsSpottingLoadingSelector } from "store/Reducers/loading.reducer";
import { SpotFilters, SpotModel, SortConfig } from "types/Models";
import { globalNavigate } from "routes/GlobalRoutes";
import { LOG_CONTACT_ROUTE } from "constants/Links";
import SpottingFilters from "components/SpottingFilters";
import BorderButtonSecondary from "components/Button/BorderButtonSecondary";
import { setAddSpotModal } from "store/Actions/logBook.actions";
import { showToast } from "helpers/Toast";
import { ToastTypes } from "types/Component";
import ActivityIndicator from "components/Loading/ActivityIndicator";
import { useDebouncedCallback } from "use-debounce";
import { MdRefresh } from "react-icons/md";
import SpotRefresh from "components/SpotRefresh";
import { useSortAndFilter } from "hooks/useSortAndFilter";

function Spotting() {
  const dispatch = useDispatch();

  const [showGridView, setShowGridView] = useState(true);
  const spotsData = useSelector(getSpotSelector);
  const spotLoading = useSelector(getIsSpottingLoadingSelector);
  const [band, setBand] = useState(spottingBandList);
  const [mode, setMode] = useState(spottingModes);

  const {
    setSortConfig,
    filters,
    setFilters,
    applyChanges,

    filterCountry,
    setFilterCountry,
    filterRegion,
    setFilterRegion,
    filterBand,
    setFilterBand,
    filterMode,
    setFilterMode,
    spottedCountry,
    setSpottedCountry,
    spottedRegion,
    setSpottedRegion,
    operatingNearMe,
    setOperatingNearMe,
    dxOnly,
    setDxOnly,
    spottedNearMe,
    setSpottedNearMe,
    potaOnly,
    setPotaOnly,
    wrlOnly,
    setWrlOnly,
    logged,
    setLogged,
  } = useSortAndFilter({ column: "", order: "" }, []);

  const onCopyPressed = (item: SpotModel) => {
    globalNavigate(`${LOG_CONTACT_ROUTE}`, {
      state: { spotData: item },
    });
    showToast({
      message: "Please choose a logbook you want to copy the spot to.",
      type: ToastTypes.WARN,
    });
  };

  const onFilterApply = (filter: SpotFilters[]) => {
    applyChanges(filter);
  };

  const refreshSpots = useCallback(() => {
    applyChanges();
  }, [dispatch]);

  const clearFilter = () => {
    applyChanges([]);
    // clear enabled bands and modes
    setBand(
      band.map((item) => {
        return {
          ...item,
          enabled: false,
        };
      })
    );
    setMode(
      mode.map((item) => {
        return {
          ...item,
          enabled: false,
        };
      })
    );
  };

  const debouncedBoundChangeListener = useDebouncedCallback(() => {
    const selectedBands = band.filter((item) => item.enabled);
    const selectedModes = mode.filter((item) => item.enabled);

    // dispatch
    const filter: SpotFilters[] = [];
    if (selectedBands.length) {
      filter.push({
        band: selectedBands,
      });
    }
    if (selectedModes.length) {
      filter.push({
        mode: selectedModes,
      });
    }
    if (filter.length) {
      setFilters(filter);
      applyChanges(filter);
    } else {
      setFilters([]);
      applyChanges([]);
    }
  }, 1000);

  return (
    <main className="relative w-full lg:w-4/5 h-full">
      <Header title="Spotting" />
      <div className="p-3 md:p-7 pb-10 dashboardWrapper overflow-x-hidden">
        <div className="flex justify-between w-full flex-wrap">
          <div className="flex flex-wrap">
            <SpottingFilters
              onFilterApply={onFilterApply}
              clearFilter={clearFilter}
              setFilters={setFilters}
              filters={filters}
              modeChange={(currentList) => {
                //if item in mode exists in current list mark is as enabled and disable otherwise
                const updatedMode = mode.map((item) => {
                  const exists = currentList.find(
                    (x: any) => x.name === item.name
                  );
                  return {
                    ...item,
                    enabled: exists ? true : false,
                  };
                });
                setMode(updatedMode);
              }}
              bandChange={(currentList) => {
                //if item in band exists in current list mark is as enabled and disable otherwise
                const updatedBand = band.map((item) => {
                  const exists = currentList.find(
                    (x: any) => x.name === item.name
                  );
                  return {
                    ...item,
                    enabled: exists ? true : false,
                  };
                });
                setBand(updatedBand);
              }}
              country={filterCountry}
              setCountry={setFilterCountry}
              region={filterRegion}
              setRegion={setFilterRegion}
              band={filterBand}
              setBand={setFilterBand}
              mode={filterMode}
              setMode={setFilterMode}
              spottedCountry={spottedCountry}
              setSpottedCountry={setSpottedCountry}
              spottedRegion={spottedRegion}
              setSpottedRegion={setSpottedRegion}
              operatingNearMe={operatingNearMe}
              setOperatingNearMe={setOperatingNearMe}
              dxOnly={dxOnly}
              setDxOnly={setDxOnly}
              spottedNearMe={spottedNearMe}
              setSpottedNearMe={setSpottedNearMe}
              potaOnly={potaOnly}
              setPotaOnly={setPotaOnly}
              wrlOnly={wrlOnly}
              setWrlOnly={setWrlOnly}
              logged={logged}
              setLogged={setLogged}
            />
            <div className="flex w-full justify-between mt-4">
              <CurrentUTCTime />
              <div className="w-[150px]">
                <BorderButtonSecondary
                  text={`Refresh Spots`}
                  onClick={refreshSpots}
                  className="sm:mb-0 cursor-pointer"
                  height="h-8"
                  childClassName="text-xs"
                  icon={
                    <MdRefresh
                      color="#fff"
                      className="mr-2 w-[18px] h-[18px]"
                    />
                  }
                />
              </div>
            </div>
            <SpotRefresh refreshSpots={refreshSpots} />

            {/* <div
              className={`bg-[#23234C] text-white text-sm font-[Barlow] px-2 w-[47.5%] sm:w-[23.5%] md:w-[100px] h-[30px] flex items-center justify-center ml-3 mb-1`}
            >
              <span className="font-[Play] mr-2">QRT</span>
              <Switch
                onChange={() => {
                  setLogged(!logged);
                }}
                checked={logged}
                uncheckedIcon={false}
                checkedIcon={false}
                height={12}
                onColor="#7B61FF"
                width={30}
              />
            </div> */}
          </div>
        </div>
        <div className="w-full">
          <SpottingMap
            spotsData={spotsData}
            spotLoading={spotsData?.length === 0 && spotLoading}
          />
          <div className="bg-[#0B0B39] w-full py-5 flex items-end flex-wrap">
            <div className="flex w-[100%] md:w-[50%] mb-4 md:mb-0 px-6">
              {band.map((item, index) => (
                <div className="w-[35px]">
                  <div
                    className={`w-full h-[10px]`}
                    style={{ backgroundColor: item.color }}
                  ></div>
                  <div className="flex flex-col items-center">
                    <span className="font-[Barlow] text-[12px] text-white">
                      {item.name}m
                    </span>
                    <input
                      type="checkbox"
                      className="w-[13px] h-[13px]"
                      checked={item.enabled}
                      onChange={() => {
                        const updatedBand = [...band];
                        updatedBand[index].enabled = !item.enabled;
                        setBand(updatedBand);
                        debouncedBoundChangeListener();
                      }}
                    />
                  </div>
                </div>
              ))}
            </div>
            <div className="flex w-[100%] md:w-[50%] px-6">
              {mode.map((item, index) => (
                <div className="w-[40px]">
                  <div className="flex flex-col items-center">
                    <span className="font-[Barlow] text-[12px] text-white">
                      {item.name}
                    </span>
                    <input
                      type="checkbox"
                      className="w-[13px] h-[13px]"
                      checked={item.enabled}
                      onChange={() => {
                        const updatedMode = [...mode];
                        updatedMode[index].enabled = !item.enabled;
                        setMode(updatedMode);
                        debouncedBoundChangeListener();
                      }}
                    />
                  </div>
                </div>
              ))}
            </div>
            <div className="h-[3px] w-full bg-[#85859C] mt-5 mb-2" />
            <div className="flex justify-between w-full mb-5 mt-5">
              <div className="flex">
                <div
                  className="w-[150px] bg-[#fff] h-[30px] flex items-center justify-center rounded-md mb-3 ml-2 cursor-pointer"
                  onClick={() => {
                    setShowGridView(!showGridView);
                  }}
                >
                  <p className="font-[Play] text-[#0243DD] text-center">
                    {showGridView ? "Table View" : "Grid View"}
                  </p>
                  <img
                    src={Icons.View}
                    alt=""
                    className="w-[15px] h-[15px] ml-2"
                  />
                </div>
              </div>
              <div className="w-[220px]">
                <BorderButtonSecondary
                  text={`+  Add Spot`}
                  onClick={() => {
                    dispatch(setAddSpotModal(true));
                  }}
                  className="sm:mb-0 cursor-pointer"
                />
              </div>
            </div>
            {spotsData?.length === 0 && spotLoading ? (
              <ActivityIndicator size={36} />
            ) : showGridView ? (
              <SpottingGridView
                spotsData={spotsData}
                onCopyPressed={onCopyPressed}
              />
            ) : (
              <SpottingTableView
                spotsData={spotsData}
                onCopyPressed={onCopyPressed}
                setSortConfig={setSortConfig}
              />
            )}
          </div>
        </div>
      </div>
    </main>
  );
}

export default Spotting;
