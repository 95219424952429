import { authErrorHandler } from "helpers/ErrorHandler/auth";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { auth } from "firebase-config";

import {
  appleLogin,
  callSignAssistantModal,
  discourseSSO,
  facebookLogin,
  googleLogin,
  loginUser,
  logoutUser,
  registerUser,
  sendEmailToVerify,
} from "store/Actions/authorization.actions";
import {
  getIsAppleAuthLoadingSelector,
  getIsAuthLoadingSelector,
  getIsFacebookAuthLoadingSelector,
  getIsGoogleAuthLoadingSelector,
} from "store/Reducers/loading.reducer";
import { globalNavigate } from "routes/GlobalRoutes";
import { useSearchParams } from "react-router-dom";
import { setDiscourseLoading } from "store/Actions/loading.action";
import { getDiscourseLoadingSelector } from "store/Reducers/authorization.reducer";
import ProfileService from "services/profile.service";
import { validCallSign, validEmail } from "helpers/Utils";
import { showToast } from "helpers/Toast";
import { ToastTypes } from "types/Component";
import CustomValidation from "helpers/Validation";

const useAuth = () => {
  const dispatch = useDispatch();
  const [userData, setUserData] = useState(false);
  const [email, setEmail] = useState<undefined | string>();
  const [password, setPassword] = useState<undefined | string>();
  const [firstName, setFirstName] = useState<undefined | string>();
  const [lastName, setLastName] = useState<undefined | string>();
  const [searchCallSign, setSearchCallSign] = useState<string>("");
  const [searchLoading, setSearchLoading] = useState<boolean>(false);
  const [forgetLoading, setForgetLoading] = useState<boolean>(false);

  const [searchParams] = useSearchParams();

  const isAuthLoading = useSelector(getIsAuthLoadingSelector);
  const isAuthFacebookLoading = useSelector(getIsFacebookAuthLoadingSelector);
  const isAuthGoogleLoading = useSelector(getIsGoogleAuthLoadingSelector);
  const isDiscourseLoading = useSelector(getDiscourseLoadingSelector);
  const isAppleAuthLoading = useSelector(getIsAppleAuthLoadingSelector);

  const signUpUserWithEmail = async () => {
    if (email && password && firstName && lastName) {
      dispatch(registerUser({ email, password, firstName, lastName }));
    } else if (!firstName) {
      authErrorHandler({ code: "First Name is required" });
    } else if (!lastName) {
      authErrorHandler({ code: "Last Name is required" });
    } else if (!email) {
      authErrorHandler({ code: "Email is required" });
    } else if (!password) {
      authErrorHandler({ code: "Password is required" });
    } else {
      authErrorHandler({ code: "Empty Fields" });
    }
  };

  const loginUserWithEmail = () => {
    if (email && password) {
      dispatch(loginUser({ email, password }));
    } else if (!email) {
      authErrorHandler({ code: "Email is required" });
    } else if (!password) {
      authErrorHandler({ code: "Password is required" });
    } else {
      authErrorHandler({ code: "Empty Fields" });
    }
  };

  const loginUserWithGoogle = () => {
    dispatch(googleLogin());
  };

  const loginWithApple = () => {
    dispatch(appleLogin());
  };

  const loginUserWithFacebook = () => {
    dispatch(facebookLogin());
  };

  const sendVerificationEmail = async () => {
    if (email && email.length) {
      if (validEmail(email)) {
        dispatch(sendEmailToVerify(email));
      } else if (CustomValidation.validateSpecialCharacters(email)) {
        setForgetLoading(true);
        const callSignResponse = await ProfileService.checkCallSignAvailability(
          email.toUpperCase()
        );
        setForgetLoading(false);

        if (callSignResponse) {
          if (callSignResponse?.available) {
            showToast({
              message: "We couldn't find any user with this callsign",
              type: ToastTypes.ERROR,
            });
          } else {
            const userEmail = callSignResponse?.data?.email;
            dispatch(sendEmailToVerify(userEmail));
          }
        } else {
          showToast({
            message: "We couldn't find any user with this callsign",
            type: ToastTypes.ERROR,
          });
        }
      } else {
        showToast({
          message: "Please enter a valid email address or callsign",
          type: ToastTypes.ERROR,
        });
      }
    } else {
      authErrorHandler({
        code: "auth/invalid-email",
      });
    }
  };

  const logOut = async () => {
    try {
      dispatch(logoutUser());
    } catch (e) {
      // console.log("ERROR WHILE LOGGING OUT : ", e);
    }
  };

  const discourseAuth = async (user: any, searchParams: any) => {
    try {
      const sso: string | null = searchParams.get("sso");
      const sig: string | null = searchParams.get("sig");

      if (sso && sig) {
        dispatch(setDiscourseLoading(true));
        const idToken: string | undefined = await auth.currentUser?.getIdToken(
          true
        );
        if (idToken) {
          dispatch(discourseSSO({ sso, sig, idToken }));
        } else {
          // console.log("DISCOURSE LOADING FALSE NO TOKEN");
          dispatch(setDiscourseLoading(false));
        }
      }
    } catch (e) {
      // console.log("DISCOURSE LOADING FALSE ERROR");

      dispatch(setDiscourseLoading(false));
    }
  };

  const navigateLogin = () => {
    const sso: string | null = searchParams.get("sso");
    const sig: string | null = searchParams.get("sig");
    if (sso && sig) {
      globalNavigate(`/login?sso=${sso}&sig=${sig}`);
    } else {
      globalNavigate("/login");
    }
  };

  const navigateToRegister = () => {
    const sso: string | null = searchParams.get("sso");
    const sig: string | null = searchParams.get("sig");
    if (sso && sig) {
      globalNavigate(`/register?sso=${sso}&sig=${sig}`);
    } else {
      globalNavigate("/register");
    }
  };

  const navigateToForgotPassword = () => {
    globalNavigate("/forgot-password");
  };

  const onCallSignSearch = async () => {
    if (searchCallSign) {
      try {
        setSearchLoading(true);
        const callSignResponse = await ProfileService.checkCallSignAvailability(
          searchCallSign
        );
        setSearchLoading(false);
        //callsign is available means that the users doesnt exist in our database
        if (callSignResponse?.available) {
          dispatch(
            callSignAssistantModal({
              data: null,
              notFound: true,
            })
          );
        } else {
          dispatch(
            callSignAssistantModal({
              data: callSignResponse?.data,
              trigger: "login",
            })
          );
        }
      } catch (e) {
        console.log("ERROR WHILE SEARCHING CALLSIGN : ", e);
        setSearchLoading(false);
      }
    } else {
      authErrorHandler({ code: "Callsign is Required" });
    }
  };

  return {
    userData,
    setUserData,
    loginUserWithEmail,
    signUpUserWithEmail,
    navigateLogin,
    sendVerificationEmail,
    loginUserWithGoogle,
    loginUserWithFacebook,
    logOut,
    email,
    setEmail,
    password,
    setPassword,
    firstName,
    setFirstName,
    lastName,
    setLastName,
    isAuthLoading,
    isAuthFacebookLoading,
    isAuthGoogleLoading,
    discourseAuth,
    navigateToRegister,
    isDiscourseLoading,
    navigateToForgotPassword,
    loginWithApple,
    isAppleAuthLoading,
    searchCallSign,
    setSearchCallSign,
    onCallSignSearch,
    searchLoading,
    forgetLoading,
  };
};

export default useAuth;
